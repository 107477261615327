import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/index.css";

import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import Homepage from './pages/Homepage';

import { SanityClient } from './client';

// Get using locale (url params)

const queryParams = new URLSearchParams(window.location.search);
const lang = queryParams.get('lang') || "en-US"; 

const SanityQuery = '*[localeCode=="'+lang+'"]';
var SanityData = [];

await SanityClient.fetch(SanityQuery)
	.then((data) => SanityData = data)
	.catch((error) => console.error('Sanity fetch error:', error));

// Fallback locale (english)

const DefaultQuery = '*[localeCode=="en-US"]';
var FallbackData = [];

if(lang !== "en_US"){
	await SanityClient.fetch(DefaultQuery)
		.then((data) => FallbackData = data)
		.catch((error) => console.error('Sanity fetch error:', error));
}

const router = createBrowserRouter([
	{
		path: "/main",
		element: <MainPage Data={{
			Default: FallbackData,
			Locale: SanityData
		}}/>,
	},
	{
		path: "/login",
		element: <LoginPage Data={{
			Default: FallbackData,
			Locale: SanityData
		}}/>,
	},
	{
		path: "/",
		element: <Homepage Data={{
			Default: FallbackData,
			Locale: SanityData
		}}/>,
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router}/>
	</React.StrictMode>
);
// Sanity Type Definitions
export const NAVIGATION = "navigation";
export const NAVIGATION_LITURGYAPPNAME = "liturgyAppName";
export const NAVIGATION_BULLETINAPPNAME = "bulletinAppName";
export const NAVIGATION_SERVICEAPPNAME = "serviceAppName";

export const FOOTER = "footer";
export const FOOTER_CREDITTEXT = "creditText";

export const TEMPLATE_EDITOR = "templateEditor";
export const TEMPLATE_EDITOR_TEMPLATESAVEBUTTONTEXT = "templateSaveButtonText";
export const TEMPLATE_EDITOR_TEMPLATECANCELBUTTONTEXT = "templateCancelButtonText";
export const TEMPLATE_EDITOR_TEMPLATENAMELABELTEXT = "templateNameLabelText";
export const TEMPLATE_EDITOR_TEMPLATENAMEDEFAULTVALUE = "templateNameDefaultValue";
export const TEMPLATE_EDITOR_TEMPLATECREATENEWSECTIONLABELTEXT = "templateCreateNewSectionLabelText";
export const TEMPLATE_EDITOR_SECTIONFIELDNAMELABELTEXT = "sectionFieldNameLabelText";
export const TEMPLATE_EDITOR_SECTIONFIELDTYPELABELTEXT = "sectionFieldTypeLabelText";
export const TEMPLATE_EDITOR_SECTIONFIELDTYPEVALUES = "sectionFieldTypeValues";
import React from "react";
import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import { MenuItem } from "@mui/material";
import {ISectionValue} from "../../helpers/ISectionValue";
import "../../css/serviceapp/section.css";

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

interface SectionProps{
    Id : string;
    Index : number;
    IsFirstElement : boolean;
    IsLastElement : boolean;
    MoveSectionUpBehavior() : any;
    MoveSectionDownBehavior() : any;
    DeleteSectionBehavior() : any;
    SectionValues: Array<ISectionValue>;
    SetSectionValues: React.Dispatch<React.SetStateAction<ISectionValue[]>>;
    FieldNameLabelText : string;
    FieldTypeLabelText : string;
    FieldTypeValues : string[];
}

export default function Section(Props:SectionProps){

    // Update SectionValues state list when Field Name is updated
    const NameChanged = (e: any) =>{

        // If SectionValues exist
        if (Props.SectionValues) {

            // Update the FieldName value of the SectionValues with the matching ID of this Section
            const updatedSectionValues = Props.SectionValues.map((value) =>
                value.Id === Props.Id 
                    ? { ...value, FieldName: e.target.value } 
                    : value
            );
            
            // Update state
            Props.SetSectionValues(updatedSectionValues); 
        } else {

            // if values is empty, make new section values with this section's data
            Props.SetSectionValues([
                {
                    Id: Props.Id,
                    Index: Props.Index,
                    FieldName: e.target.value,
                    FieldType: '',
                }
            ]);
        }
    }

    // Update SectionValues state list when Field Type is updated
    const TypeChanged = (e: any) => {

        // If SectionValue exists
        if (Props.SectionValues) {

            // Update the FieldType value of the SectionValues with the matching ID of this section
            const updatedSectionValues = Props.SectionValues.map((value) =>
                value.Id === Props.Id 
                    ? { ...value, FieldType: e.target.value } 
                    : value
            );
            
            // Update state
            Props.SetSectionValues(updatedSectionValues); 
        } else {

            // If values empty, make a new one with this section's data
            Props.SetSectionValues([
                {
                    Id: Props.Id,
                    Index: Props.Index,
                    FieldName: '',
                    FieldType: e.target.value,
                }
            ]);
        }
    };

    // Render this component's TSX
    return (
        <Row key={Props.Index} className={"section-main justify-content-md-center"}>

            {/** The left side of the section, the up and down arrows */}
            <Col xs={1} className="section-icon-wrapper">
                <div 
                    className="section-icon section-icon-uparrow" 
                    onClick={!Props.IsFirstElement ? Props.MoveSectionUpBehavior : () => {}}>
                        <KeyboardArrowUpRoundedIcon color={Props.IsFirstElement ? "disabled" : "inherit"}/>
                </div>
                <div 
                    className="section-icon section-icon-downarrow" 
                    onClick={!Props.IsLastElement ? Props.MoveSectionDownBehavior : () => {}}>
                        <KeyboardArrowDownRoundedIcon color={Props.IsLastElement ? "disabled" : "inherit"}/>
                </div>
            </Col>

            {/** The center of the section, the main part, the FieldName field */}
            <Col xs={3} className="section-field-wrapper" id="left">
                <TextField
                    className={"section-field-name"}
                    required
                    size="small"
                    id="outlined-required"
                    label={Props.FieldNameLabelText}
                    value={Props.SectionValues.find((e) => e.Id === Props.Id)?.FieldName}
                    onChange={NameChanged}/>
            </Col>

            {/** The center of the section, the main part, the FieldType dropdown */}
            <Col xs={3} className="section-field-wrapper" id="right">
                <TextField
                    className={"section-field-type"}
                    select
                    required
                    size="small"
                    id="outlined-required"
                    label={Props.FieldTypeLabelText}
                    value={Props.SectionValues.find((e) => e.Id === Props.Id)?.FieldType}
                    onChange={TypeChanged}>
                        {
                            Props.FieldTypeValues.map((e, i)=>{
                                return (
                                    <MenuItem   
                                        className="section-field-type-menuitem"
                                        key={"field-type-"+i} 
                                        value={e}>
                                        {e}
                                    </MenuItem>
                                )
                            })
                        }
                </TextField>
            </Col>

            {/** The right side of the section with the delete button */}
            <Col xs={1} className="section-icon-wrapper" id="right">
                <div 
                    className="section-icon section-icon-delete"
                    onClick={Props.DeleteSectionBehavior}>
                        <DeleteForeverRoundedIcon color="error"/>
                </div>
            </Col>
        </Row>
    );
}
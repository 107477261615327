import React from "react";
import {Container} from "react-bootstrap";

import LogoImage from "../icon/temp/temp-icon-colour.png";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

import { GetData } from "../client";

import {
    FOOTER, 
    FOOTER_CREDITTEXT,
    NAVIGATION, 
    NAVIGATION_LITURGYAPPNAME 
} from "../helpers/GlobalConstants";

export default function Homepage(Props:any){

    const data = Props.Data;
    const navigationAppName = GetData(data, NAVIGATION, NAVIGATION_LITURGYAPPNAME);
    const footerCreditText = GetData(data, FOOTER, FOOTER_CREDITTEXT);

    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#303030" 
                BackgroundCircle="#FFFFFF" 
                NavigationTextColor="#FFFFFF" 
                AppName={navigationAppName}/>
            <Footer
                CreditText={footerCreditText}
                BackgroundColor="#303030"
                FooterTextColor="#FFFFFF"/>
        </Container>
    );
}

import React from "react";
import {Container} from "react-bootstrap";

import LogoImage from "../icon/temp/temp-icon-colour.png";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import TemplateEditor from "../components/serviceapp/TemplateEditor";

import { GetData } from "../client";

import {
    FOOTER, 
    FOOTER_CREDITTEXT,
    NAVIGATION, 
    NAVIGATION_SERVICEAPPNAME, 
    TEMPLATE_EDITOR,
    TEMPLATE_EDITOR_SECTIONFIELDNAMELABELTEXT,
    TEMPLATE_EDITOR_SECTIONFIELDTYPELABELTEXT,
    TEMPLATE_EDITOR_SECTIONFIELDTYPEVALUES,
    TEMPLATE_EDITOR_TEMPLATECANCELBUTTONTEXT,
    TEMPLATE_EDITOR_TEMPLATECREATENEWSECTIONLABELTEXT,
    TEMPLATE_EDITOR_TEMPLATENAMEDEFAULTVALUE,
    TEMPLATE_EDITOR_TEMPLATENAMELABELTEXT,
    TEMPLATE_EDITOR_TEMPLATESAVEBUTTONTEXT
} from "../helpers/GlobalConstants";

export default function MainPage(Props:any){

    const data = Props.Data;
    const serviceAppName = GetData(data, NAVIGATION, NAVIGATION_SERVICEAPPNAME);
    const footerCreditText = GetData(data, FOOTER, FOOTER_CREDITTEXT);
    const templateSaveButtonText = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATESAVEBUTTONTEXT);
    const templateCancelButtonText = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATECANCELBUTTONTEXT);
    const templateNameLabelText = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATENAMELABELTEXT);
    const templateNameDefaultValue = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATENAMEDEFAULTVALUE);
    const templateCreateNewSectionLabelText = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_TEMPLATECREATENEWSECTIONLABELTEXT);
    const sectionFieldNameLabelText = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDNAMELABELTEXT);
    const sectionFieldTypeLabelText = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDTYPELABELTEXT);
    const sectionFieldTypeValues = GetData(data, TEMPLATE_EDITOR, TEMPLATE_EDITOR_SECTIONFIELDTYPEVALUES);

    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#E2FFE2" 
                BackgroundCircle="#303030" 
                NavigationTextColor="#303030" 
                AppName={serviceAppName}/>
            <TemplateEditor 
                CancelButtonBehavior={() => {}} 
                SaveButtonBehavior={() => {}}
                TemplateSaveButtonText={templateSaveButtonText}
                TemplateCancelButtonText={templateCancelButtonText}
                TemplateNameLabelText={templateNameLabelText}
                TemplateNameDefaultValue={templateNameDefaultValue}
                TemplateCreateNewFieldLabelText={templateCreateNewSectionLabelText}
                SectionFieldNameLabelText={sectionFieldNameLabelText}
                SectionFieldTypeLabelText={sectionFieldTypeLabelText}
                SectionFieldTypeValues={sectionFieldTypeValues}/>
            <Footer
                CreditText={footerCreditText}
                BackgroundColor="#303030"
                FooterTextColor="#FFFFFF"></Footer>
        </Container>
    );
}

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import LogoImage from "../icon/temp/temp-icon-colour.png";
import Navigation from "../components/Navigation";
import Footer from '../components/Footer';
import LoginForm from '../components/LoginForm';

import { GetData } from "../client";

import {
    FOOTER, 
    FOOTER_CREDITTEXT,
    NAVIGATION, 
    NAVIGATION_LITURGYAPPNAME 
} from "../helpers/GlobalConstants";

export default function LoginPage(Props:any) {

    const data = Props.Data;
    const navigationAppName = GetData(data, NAVIGATION, NAVIGATION_LITURGYAPPNAME);
    const footerCreditText = GetData(data, FOOTER, FOOTER_CREDITTEXT);

    return (
        <Container fluid>
            <Navigation 
                LogoImage={LogoImage} 
                BackgroundColour="#303030" 
                BackgroundCircle="#FFFFFF" 
                NavigationTextColor="#FFFFFF" 
                AppName={navigationAppName}></Navigation>
            <LoginForm BackgroundColor='White'></LoginForm>
            <Footer
                CreditText={footerCreditText}
                BackgroundColor="#303030"
                FooterTextColor="#FFFFFF"></Footer>
        </Container>
    )
}


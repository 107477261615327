import React, {useState, useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import {v4 as uuidv4} from 'uuid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Section from "./Section";
import {ISectionValue} from "../../helpers/ISectionValue";
import "../../css/serviceapp/template-editor.css";

// Control debug
var debug : boolean = false;

// Props Interface
interface TemplateEditorProps{
    Sections?: Array<ISectionValue>;
    CancelButtonBehavior() : any;
    SaveButtonBehavior() : any;
    TemplateSaveButtonText : string;
    TemplateCancelButtonText : string;
    TemplateNameLabelText : string;
    TemplateNameDefaultValue : string;
    TemplateCreateNewFieldLabelText : string;
    SectionFieldNameLabelText : string;
    SectionFieldTypeLabelText : string;
    SectionFieldTypeValues : string[];
}

// Component function "TemplateEditor"
export default function TemplateEditor(Props:TemplateEditorProps){

    // Grab React states for values from Sections component
    const [sectionValues, setSectionValues] = useState<Array<ISectionValue>>(Props.Sections ? Props.Sections : []);

    // Handler function for deleting a section
    const DeleteSection = (id: string) => {
        // Define a number to handle the index shifts
        /// For example if you delete index 3 then index 4 has to shift up to take its place
        var shiftFrom : number = 0;

        // Set the new section values list
        /// First sort it in asc order
        /// then remove the element with the specified id in param
        /// then shift the rest of the indices
        const newSectionValues = sectionValues
            .sort((a,b) => a.Index - b.Index)
            .filter((e, i) => {
                if(e.Id === id){
                    shiftFrom = e.Index+1;
                    return false;
                }
                return true;
            })
            .map((e) => {
                if(e.Index >= shiftFrom){
                    return {...e, Index : e.Index - 1};
                }
                else{
                    return e;
                }
            });

        // Set new section values list
        setSectionValues(newSectionValues);
    }

    // Handler function for when up and down arrow button is clicked on the Section component
    const MoveSection = (index: number, direction: "up" | "down") => {

        // When direction to move is "up"
        if(direction === "up"){

            // Find the ids of the current and previous sections
            var current = sectionValues.find((e) => e.Index === index)?.Id;
            var previous = sectionValues.find((e) => e.Index === index - 1)?.Id;

            // Set the new section values list
            /// swap the current and previous' indices
            const newSectionValues = sectionValues.map((e) => {
                if(e.Id === current){
                    return {...e, Index: e.Index - 1};
                }
                else if(e.Id === previous){
                    return {...e, Index: e.Index + 1};
                }
                else{
                    return e;
                }
            });

            // Set new section values to the state list
            setSectionValues(newSectionValues);
        }

        // When direction to move is "down"
        if(direction === "down"){

            // Find ids of current and next sections
            var current = sectionValues.find((e) => e.Index === index)?.Id;
            var next = sectionValues.find((e) => e.Index === index + 1)?.Id;

            // Create new updated section list
            /// Swap current and next indices 
            const newSectionValues = sectionValues.map((e) => {
                if(e.Id === current){
                    return {...e, Index: e.Index + 1};
                }
                else if(e.Id === next){
                    return {...e, Index: e.Index - 1};
                }
                else{
                    return e;
                }
            });

            // Set section values list with new updated list
            setSectionValues(newSectionValues);
        }
    };

    // Handler function for when new section is created
    const CreateSection = () => {

        // Gets the latest index #
        var index = sectionValues.length;

        // Generate UUID
        var id = uuidv4();

        // Create sectionValue object (holds the data of the sections) for this new section being created
        var sectionValue : ISectionValue = {
            Id: id,
            Index: index,
            FieldName: "",
            FieldType: Props.SectionFieldTypeValues[0]
        };

        // Update the state that holds the list of sectionValues of the page
        setSectionValues([...sectionValues, sectionValue]);
    }
    // For Debug
    useEffect(() => {
        if(debug){
            console.log(sectionValues); 
        }
    }, [sectionValues]);

    // Component's JSX value
    return (
        <Row className={"template-editor-main justify-content-md-center"}>

            {/** First row with the Cancel and Save buttons */}
            <Row className={"template-editor-buttons justify-content-md-center"}>
                <Col xs={{span:3, offset: 3}}>
                    <Button size="small" color="error" variant="outlined">{Props.TemplateCancelButtonText}</Button>
                    <Button size="small" variant="contained">{Props.TemplateSaveButtonText}</Button>
                </Col>
            </Row>

            {/** Second row with the Template Name field */}
            <Row className={"template-editor-title justify-content-md-center"}>
                <Col xs={{span:6}}>
                    <TextField
                        className={"template-editor-title-field"}
                        required
                        size="small"
                        id="outlined-required"
                        label={Props.TemplateNameLabelText}
                        defaultValue={Props.TemplateNameDefaultValue}
                    />
                </Col>
            </Row>

            {/** Generate Section react components based on the list of section values */}
            {
                sectionValues
                    .sort((a, b) => a.Index - b.Index)
                    .map((sectionValue, i) => (
                        <Section
                            key={i}
                            Index={sectionValue.Index}
                            Id={sectionValue.Id}
                            IsFirstElement={sectionValue.Index === 0}
                            IsLastElement={sectionValue.Index === sectionValues.length-1}
                            MoveSectionUpBehavior={() => MoveSection(sectionValue.Index, "up")}
                            MoveSectionDownBehavior={() => MoveSection(sectionValue.Index, "down")}
                            DeleteSectionBehavior={() => DeleteSection(sectionValue.Id)}
                            SectionValues={sectionValues}
                            SetSectionValues={setSectionValues}
                            FieldNameLabelText={Props.SectionFieldNameLabelText}
                            FieldTypeLabelText={Props.SectionFieldTypeLabelText}
                            FieldTypeValues={Props.SectionFieldTypeValues}
                        />
                    ))
            }

            {/** Last row with the button to create new section */}
            <Row className="template-editor-section-new justify-content-md-center">
                <Col xs={6} className="template-editor-section-new-button" onClick={() => CreateSection()}>
                    {Props.TemplateCreateNewFieldLabelText}
                </Col>
            </Row>

        </Row>
    );
}
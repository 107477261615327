import { createClient } from "@sanity/client";

export const SanityClient = createClient({
	projectId: String(process.env.REACT_APP_SANITY_STUDIO_PROJECT_ID),
	dataset: String(process.env.REACT_APP_SANITY_STUDIO_DATASET),
	apiVersion: "2024-01-01",
	useCdn: false,
});

export const GetData = (Data : {Default : any[], Locale : any[]}, Type : string, Field : string ) => {
	console.log(Data)
	if (Data.Locale.length > 0){
		try{
			return Data.Locale.filter(e => e['_type'] === Type)[0][Field];
		}catch{
			return GetFallbackData(Data.Default, Type, Field);
		}
	}else{
		return GetFallbackData(Data.Default, Type, Field);
	}
}

const GetFallbackData = (Default : any[], type : string, field : string) => {
	if(Default.length > 0){
		return Default.filter(e => e['_type'] === type)[0][field];
	}else{
		return "";
	}
}